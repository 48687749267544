import React, { useEffect } from "react";
import AOS from 'aos';
import "../assets/css/style.css";
import "../assets/css/all.css";
function Contact() {
    useEffect(() => {
            AOS.init({
                duration: 2000,
                once: true,
            });
        },
        []);
    // @ts-ignore
    return (
        <section>
            <div className="container">
                <h2
                    id={"contact"}
                    className={"mob-connect enabledisable page-scroll"}
                    style={{ fontSize: "40px", fontWeight: 700, marginTop: "50px" }}
                >
                    <span className="low-highlight-hazeskin ">Contact with me</span>
                </h2>
                <ul className={"socialsites"}>
                    <li>
                        <a href="https://github.com/saikiranreddyappidi/"><i className="fab fa-github"></i></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/saikiranreddyappidi/"><i
                            className="fab fa-linkedin"></i></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/saikiranappidi/"><i className="fab fa-facebook"></i></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/saikiranreddyappidi"><i className="fab fa-instagram"></i></a>
                    </li>
                </ul>
            </div>
        </section>
);
}

export default Contact;