import React, { useEffect } from "react";
import AOS from 'aos';
import "../assets/css/style.css";
import "../assets/css/all.css";
import leetcode from "../assets/leetcode.svg";
function Contact() {
    useEffect(() => {
            AOS.init({
                duration: 2000,
                once: true,
            });
        },
        []);
    // @ts-ignore
    return (
            <div className="container">
                <h2
                    id={"coding"}
                    style={{ fontSize: "56px", fontWeight: 700, marginTop: "50px" }}
                >
                    <span className="low-highlight-yellow">Coding Profile</span>
                </h2>
                <div className="achievement-cards-div">
                    <a
                        href={"https://www.hackerrank.com/saikiranappidi"}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        style={{ textDecoration: "none" }}
                    >
                        <div className={"certificate-card"}>
                            <div className={"certificate-image-div"}>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/HackerRank_Icon-1000px.png/512px-HackerRank_Icon-1000px.png"
                                    alt="HackerRank"
                                    className={"card-image"}
                                />
                            </div>
                            <div className={"certificate-detail-div"}>
                                <h5 className={"card-title"}>HackerRank</h5>
                                <p className={"card-subtitle"}>Certified in</p>
                            </div>
                            <div className={"certificate-card-footer"}>
                                <span className="certificate-tag">Python</span>
                                <span className="certificate-tag">Problem Solving</span>
                                <span className="certificate-tag">C</span>
                            </div>
                        </div>
                    </a>
                    <div className={"certificate-card"}>
                        <a
                            href={"https://leetcode.com/saikiranreddyappidi/"}
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            style={{ textDecoration: "none" }}
                        >
                            <img
                                src={leetcode}
                                alt={"LeetCode"}
                                className={"card-image"}
                                style={{ width: "380px", height: "380px" }}
                            />
                        </a>
                    </div>
                    <a
                    href={"https://www.codechef.com/users/saikiranreddya"}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    style={{ textDecoration: "none" }}
                    >
                        <div className={"certificate-card"}>
                            <div className={"certificate-image-div"}>
                                <img
                                    src={"https://i.pinimg.com/564x/c5/d9/fc/c5d9fc1e18bcf039f464c2ab6cfb3eb6.jpg"}
                                    alt={"CodeChef"}
                                    className={"card-image"}
                                />
                            </div>
                            <div className={"certificate-detail-div"}>
                                <h5 className={"card-title"}>CodeChef</h5>
                                <p className={"card-subtitle"}>Participated in 35+ contests</p>
                            </div>
                            <div className={"certificate-card-footer"}>
                                <span className={"certificate-tag"}>1498 Rating</span>
                                <span className={"certificate-tag"}>2 Starts</span>
                                <span className={"certificate-tag"}>284 Solved</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
);
}

export default Contact;