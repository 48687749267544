import React, { useState, useEffect } from "react";
import Preloader from "./components/Pre";
import "./assets/css/style.css";
import "./assets/css/all.css";
import Content from "./components/Content";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Coding from "./components/Coding";
import Contact from "./components/Contact";
import {
    BrowserRouter,
    Route,
    Routes,
    Navigate, NavLink
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    const [load, updateLoad] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            updateLoad(false);
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    return (
        <BrowserRouter>
            <Preloader load={load} />
            <div className="App" id={load ? "no-scroll" : "scroll"}>
                <ScrollToTop />
                <section>
                    <div>
                        <div id={"top"} className={"page-scroll"}></div>
                        <header>
                            <a href={"#top"} className={"logo"}>Sai Kiran Reddy</a>
                            <ul>
                                <li><NavLink to="/" activeClassName="active" className="page"
                                                id="home-btn">Home</NavLink></li>
                                <li><NavLink to="/about" activeClassName="active" className="page" id="about-btn">About
                                        Me</NavLink></li>
                                <li><NavLink to="/experience" activeClassName="active" className="page"
                                                 id="experience-btn">Experience</NavLink></li>
                                <li><NavLink to="/projects" activeClassName="active" className="page"
                                                 id="project-btn">Projects</NavLink></li>
                                <li><NavLink to="/coding" activeClassName="active" className="page"
                                                 id="codings">Coding</NavLink></li>
                            </ul>
                        </header>
                    </div>
                    <Routes>
                        <Route path="/" element={<Content/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/experience" element={<Experience/>}/>
                        <Route path="/projects" element={<Projects/>}/>
                        <Route path="/coding" element={<Coding/>}/>
                        <Route path="/coding" element={<Contact/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </section>
            </div>
        </BrowserRouter>
    );
}

export default App;