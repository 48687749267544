import React, { useEffect } from "react";
import AOS from 'aos';
import "../assets/css/style.css";
import "../assets/css/all.css";
import WebProject from "./Projects/WebProject";
import Calculator from "./Projects/Calculator";
import TaskManagementSystem from "./Projects/TaskManagementSystem";
import SpringProfiles from "./Projects/SpringProfiles";
import CMS from "./Projects/CMS";

function Projects() {
    useEffect(() => {
            AOS.init({
                duration: 2000,
                once: true,
            });
        },
        []);
    // @ts-ignore
    return (
            <div className={"container"}>
                <h2
                    id={"projects"}
                    className={"mob-h2 page-scroll"}
                    style={{ fontSize: "56px", fontWeight: 700, marginTop: "50px" }}
                >
                    <span className="low-highlight-cyan" style={{ alignContent: "center"}}>Projects</span>
                </h2>
                <div className={"projects"} data-aos={"fade-up"} data-aos-duration={"1500"}>
                    <WebProject />
                    <Calculator />
                    <TaskManagementSystem />
                    <SpringProfiles/>
                    <CMS/>
                </div>
            </div>
);
}

export default Projects;