import React, { useEffect } from "react";
import AOS from 'aos';
import "../assets/css/style.css";
import "../assets/css/all.css";
import Vector10 from "../assets/Vector 10.png";
import Vector11 from "../assets/Vector 11.png";
import Vector16 from "../assets/Vector 16.png";
import Vector27 from "../assets/Vector 27.png";
import APSOCTOCAT from "../assets/APS-OCTOCAT.png";
import resume from "../assets/Sai Kiran's resume.pdf"
function Content() {
    useEffect(() => {
            AOS.init({
                duration: 2000,
                once: true,
            });
        },
        []);
    // @ts-ignore
    return (
        <div className={"container"}>
            <div className={"content"}>
                <div className={"column"}>
                    <h2 data-aos="fade-down" data-aos-duration="1000">Hi all, I'm Sai Kiran<span
                        className="wave">👋</span></h2>
                    <p data-aos="fade-right" data-aos-duration="1000">
                        I love software development, cloud computing, backend development and android app
                        development. I
                        know a little bit of machine learning too and NLP! Want to discuss
                        regarding any project or just want to say a Hi? I am always
                        available to listen you :)
                    </p>
                    <a href="mailto:saikiranappidi@gmail.com" data-aos="fade-right" data-aos-duration="1000">Contact
                        Me</a>
                    <a href={resume} target={"_blank"} rel={"noopener noreferrer"} data-aos="fade-right"
                       data-aos-duration="1000">Resume</a>
                </div>
                
                <div className={"img"}>
                    <img
                        src={Vector10}
                        alt={"Vector 10"}
                        className={"shape"}
                        data-aos="fade-up-right"
                        data-aos-duration="1000"
                        style={{top: "5rem", right: "15rem"}}
                    />
                    <img
                        src={Vector11}
                        alt={"Vector 11"}
                        className={"shape"}
                        data-aos="fade-up-right"
                        data-aos-duration="1000"
                        style={{height: "8rem", width: "8rem", top: "8rem", right: "20rem"}}
                    />
                    <img
                        src={Vector16}
                        alt={"Vector 16"}
                        className={"shape"}
                        data-aos="fade-up-right"
                        data-aos-duration="1000"
                        style={{height: "8rem", width: "8rem", top: "8rem", right: "15rem"}}
                    />
                    <img
                        src={Vector10}
                        alt={"Vector 10"}
                        className={"shape"}
                        data-aos="fade-up-right"
                        data-aos-duration="1000"
                        style={{zIndex: 1, top: "23rem", right: "9rem"}}
                    />
                    <img
                        src={Vector27}
                        alt={"Vector 27"}
                        className={"shape"}
                        data-aos="fade-up-right"
                        data-aos-duration="1000"
                        style={{zIndex: 1, height: "8rem", width: "8rem", top: "25rem", right: "13rem"}}
                    />
                    <img
                        src={Vector11}
                        alt={"Vector 11"}
                        className={"shape"}
                        data-aos="fade-up-right"
                        data-aos-duration="1000"
                        style={{zIndex: 1, height: "4rem", width: "4rem", top: "26rem", right: "9rem"}}
                    />
                    <img
                        src={APSOCTOCAT}
                        alt={"APSOCTOCAT"}
                        data-aos="fade-up-right"
                        data-aos-duration="1000"
                    />
                </div>
            </div>
        </div>
    
    );
}

export default Content;