import React, { useEffect } from "react";
import AOS from 'aos';
import "../assets/css/style.css";
import "../assets/css/all.css";

function About() {
    useEffect(() => {
            AOS.init({
                duration: 2000,
                once: true,
            });
        },
        []);
    // @ts-ignore
    return (
        <div className={"container"}>
                <div id={"about"} className={" content page-scroll"} style={{ justifyContent: "space-around" }}>
                    <div className={"img"} data-aos="fade-up-right" data-aos-duration="1000">
                        <img
                            src="https://media.giphy.com/media/7wA0YhMXvDBhTckOGM/source.gif?cid=790b7611b6565a9092ee51eeee34015858a0783fd77b6f31&rid=source.gif&ct=s"
                            alt="APSOCTOCAT"
                        />
                    </div>
                    <div className={"column-2"}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version={"1.1"}
                            className={"svg-filters"}
                            style={{ display: "none" }}
                        >
                            <defs>
                                <filter id="marker-shape">
                                    <feTurbulence
                                        type={"fractalNoise"}
                                        baseFrequency={"0 0.15"}
                                        numOctaves={1}
                                        result="warp"
                                    />
                                    <feDisplacementMap
                                        xChannelSelector={"R"}
                                        yChannelSelector={"G"}
                                        scale={30}
                                        in="SourceGraphic"
                                        in2="warp"
                                    />
                                </filter>
                            </defs>
                        </svg>
                        <h2 style={{ fontWeight: 550 }}>
                            <span className={"low-highlight-pink"}>What I do</span>
                        </h2>
                        <h3 style={{ fontSize: "24px", color: "#444444", fontWeight: 1 }}>
                            I love all aspects of technology and want to explore as much as I can!
                        </h3>
                        <div className="dev-icons">
                            <ul>
                                <li className={"software-skill-inline python"}>
                                    <i className={"devicon-python-plain"}></i>
                                    <p>Python</p>
                                </li>
                                <li className={"software-skill-inline flask"}>
                                    <i className={"devicon-django-plain"}></i>
                                    <p>Django</p>
                                </li>
                                <li className="software-skill-inline java">
                                    <i className="devicon-java-plain"></i>
                                    <p>Java</p>
                                </li>
                                <li className="software-skill-inline mongodb">
                                    <i className="devicon-mongodb-plain"></i>
                                    <p>Mongodb</p>
                                </li>
                                <li className="software-skill-inline mysql">
                                    <i className="devicon-mysql-plain"></i>
                                    <p>My SQL</p>
                                </li>
                                <li className="software-skill-inline linux">
                                    <i className="devicon-linux-plain"></i>
                                    <p>Linux</p>
                                </li>
                                <li className="software-skill-inline github">
                                    <i className="devicon-github-plain"></i>
                                    <p>GitHub</p>
                                </li>
                                <li className="software-skill-inline c">
                                    <i className="devicon-c-plain"></i>
                                    <p>C</p>
                                </li>
                                <br></br>
                                <li className="software-skill-inline selenium">
                                    <i className="devicon-selenium-plain"></i>
                                    <p>Selenium</p>
                                </li>
                                <li className="software-skill-inline html5">
                                    <i className="devicon-html5-plain"></i>
                                    <p>HTML5</p>
                                </li>
                                <li className="software-skill-inline css3">
                                    <i className="devicon-css3-plain"></i>
                                    <p>CSS3</p>
                                </li>
                                <li className="software-skill-inline javascript">
                                    <i className="devicon-javascript-plain"></i>
                                    <p>JavaScript</p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p style={{ color: "#444444", fontSize: "20px" }}>
                                ⚡ Develop web and mobile applications
                            </p>
                            <p style={{color: "#444444", fontSize: "20px"}}>
                               ⚡ Deploy and maintain applications on cloud
                            </p>
                            <p style={{ color: "#444444", fontSize: "20px" }}>
                                ⚡ Automate tasks using Python and Selenium Testing
                            </p>
                        </div>
                    </div>
                </div>
        </div>
);
}

export default About;